import request from "@/utils/request";
//场景数据一览
export function listScenarioBasicPage(data) {
  return request({
    url: "/system/scenarioBasic/listScenarioBasicPage",
    method: "post",
    data
  });
}

//单个查询
export function selectById(data) {
  return request({
    url: "/system/scenarioBasic/selectById",
    method: "get",
    params: data
  });
}

// 删除
export function deleteById(data) {
  return request({
    url: "/system/scenarioBasic/deleteById",
    method: "get",
    params: data
  });
}

// 新增
export function insertScenarioBasic(data) {
  return request({
    url: "/system/scenarioBasic/insertScenarioBasic",
    method: "post",
    data
  });
}

// 修改
export function updateScenarioBasic(data) {
  return request({
    url: "/system/scenarioBasic/updateScenarioDto",
    method: "post",
    data
  });
}
// 智能查询-
export function getByAssociatedScenarios(data) {
  return request({
    url: "/system/scenarioBasic/getByAssociatedScenarios",
    method: "get",
    params: data
  });
}

// 场景知识标签查询-
export function getByScenarioLabel(data) {
  return request({
    url: "/system/scenarioBasic/getByScenarioLabel",
    method: "get",
    params: data
  });
}

// 企业行为 046
export function getDicRadioButton(data) {
  return request({
    url: "/system/code/getCodeByStatus",
    method: "get",
    params: data
  });
}

// 产业链
export function selectAllIndustrialChainName(data) {
  return request({
    url: "/system/industrialChain/selectAllIndustrialChainName",
    method: "get",
    params: data
  });
}

// 产业链标签
export function getAllIndustrialChainName(data) {
  return request({
    url: "/system/industrialChain/getAllIndustrialChainName",
    method: "get",
    params: data
  });
}

// 行业标签
export function getIndustryThree(data) {
  return request({
    url: "/system/industry/getIndustryThree",
    method: "get",
    params: data
  });
}
//企业行为知识列表
export function selectBehaviorLabelPage(data) {
  return request({
    url: "/system/companyBehaviorLabel/selectBehaviorLabelPage",
    method: "post",
    data
  });
}
//企业行为知识删除
export function ehaviorLdeleteById(data) {
  return request({
    url: "/system/companyBehaviorLabel/deleteById",
    method: "post",
    data
  });
}
//企业行为知识新增
export function insertBehaviorLabel(data) {
  return request({
    url: "/system/companyBehaviorLabel/insertBehaviorLabel",
    method: "post",
    data
  });
}
//企业行为知识编辑
export function updateBehaviorLabelById(data) {
  return request({
    url: "/system/companyBehaviorLabel/updateBehaviorLabelById",
    method: "post",
    data
  });
}
//企业行为知识详情
export function selectBehaviorLabelById(data) {
  return request({
    url: "/system/companyBehaviorLabel/selectBehaviorLabelById",
    method: "get",
    params: data
  });
}
//模糊查询产品数据
export function getProductByName(data) {
  return request({
    url: "/system/companyBehaviorLabel/getProductByName",
    method: "get",
    params: data
  });
}
//生产工艺列表
export function selectGenerationProcessPage(data) {
  return request({
    url: "/system/generationProcess/selectGenerationProcessPage",
    method: "post",
    data
  });
}
//生产工艺新增
export function insertGenerationProcess(data) {
  return request({
    url: "/system/generationProcess/insertGenerationProcess",
    method: "post",
    data
  });
}
export function getIndustryLabelList() {
  return request({
    url: "/system//label/getIndustryLabelList",
    method: "get",
    
  });
}
