<template>
  <div class="editor">
    <div ref="toolbar" class="toolbar"></div>
    <div ref="editor" class="text"></div>
  </div>
</template>

<script>
import E from "wangeditor";
// import { apiUpload } from "@/api/content";
import { apiUploadStr } from "@/api/common";
export default {
  name: "Editorbar",
  data() {
    return {
      info: null,
      editor: "",
      isChange: false,
    };
  },
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    isClear: {
      type: Boolean,
      default: false,
    },
    maxlength:{
      type: Number,
      default: 10000,
    }
  },
  watch: {
    isClear(val) {
      // 触发清除文本域内容
      if (val) {
        this.editor.txt.clear();
        this.info = null;
      }
    },
    value(val) {
      this.editor.txt.html(this.value);
      this.isChange = false;
    },
  },
  mounted() {
    this.seteditor();
  },
  methods: {
    seteditor() {
      this.editor = new E(this.$refs.toolbar, this.$refs.editor);
      this.editor.config.uploadImgHeaders = {
        token: sessionStorage.getItem("token"),
      }; // 自定义 header
      this.editor.config.uploadFileName = "image"; // 后端接受上传文件的参数名
      this.editor.config.uploadImgMaxSize = 12 * 1024 * 1024; // 将图片大小限制为 12M
      this.editor.config.uploadImgMaxLength = 6; // 限制一次最多上传 3 张图片
      this.editor.config.uploadImgTimeout = 3 * 60 * 1000; // 设置超时时间
      this.editor.config.zIndex = 200;
    
      // 4.自定义控制上传过程--上传到阿里云
      this.editor.config.customUploadImg = async (files, insert) => {
        const fileData = new FormData();
        fileData.append("file", files[0]);
        const res = await apiUploadStr(fileData);
        if (res.code == 200) {
          insert(res.data);
        }
      };
      // 配置菜单
      this.editor.config.menus = [
        "fontName", //字体
        "bold", // 粗体
        "underline", // 下划线
        "link", // 插入链接
        "quote", // 引用
        "emoticon", // 表情
        "image", // 插入图片
        "justify", // 对齐方
        "fontSize", // 字体大小
        "foreColor", // 文字颜色
        "backColor", // 背景颜色
        // 'table', // 表格
        // 'code' // 插入代码
      ];
      this.editor.config.fontNames = [
        "先华文字体",
        "兰亭字体",
        "楷体",
        "微软雅黑",
        "黑体",
        "仿宋",
        "楷体",
        "标楷体",
        "华文仿宋",
        "华文楷体",
        "宋体",
        "微软雅黑",

        "Arial",
        "Verdana",
        "Georgia",
      ];

      this.editor.config.uploadImgHooks = {
        fail: (xhr, editor, result) => {
          // 插入图片失败回调
        },
        success: (xhr, editor, result) => {
          // 图片上传成功回调
          //
          let imgUrl = result.data;
          insertImg(imgUrl);
        },
        timeout: (xhr, editor) => {
          // 网络超时的回调
        },
        error: (xhr, editor) => {
          // 图片上传错误的回调
        },
        customInsert: (insertImg, result, editor) => {
          // 图片上传成功,插入图片的回调
          var url = result.data;
          insertImg(url); //将内容插入到富文本中
        },
      };

      this.editor.config.onchange = (html) => {
        this.isChange = true;
        this.info = html; // 绑定当前逐渐地值
        this.$emit("change", this.info); // 将内容同步到父组件中
      };
      // 创建富文本编辑器
      this.editor.create();
      this.editor.txt.html(this.value);
    },
  },
};
</script>

<style lang="less" scope>
.editor {
  width: 100%;
  height: 100%;

  .text {
    border: 1px solid #ccc;
    min-height: 144px;
    max-height: 434px;
  }
}
.toolbar {
  border: 1px solid #ccc;
  margin-bottom: -1px;
}

.w-e-toolbar {
  position: static;
}
.w-e-text-container {
  position: static;
  .w-e-text {
    max-height: 434px;
  }
}
// /deep/.w-e-text {
//   max-height: 434px;
// }
</style>
